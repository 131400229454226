var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasPermission('QUERY_CAKE_MAILL'))?_c('a-card',{staticStyle:{"background-color":"#fff"}},[_c('div',{class:_vm.advanced ? 'search' : null},[_c('a-form',{attrs:{"layout":"horizontal"}},[_c('div',{class:_vm.advanced ? null : 'fold'},[_c('a-row',[_c('a-col',{staticStyle:{"max-width":"400px"},attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户经理","labelCol":{ span: 5 },"wrapperCol":{ span: 18, offset: 1 }}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"allowClear":"","show-search":"","mode":"multiple","filter-option":(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; },"placeholder":"请选择客户经理","default-value":null,"option-label-prop":"label"},model:{value:(_vm.queryData.staffIds),callback:function ($$v) {_vm.$set(_vm.queryData, "staffIds", $$v)},expression:"queryData.staffIds"}},_vm._l((_vm.users),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"label":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{staticStyle:{"max-width":"400px"},attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"创建时间","labelCol":{ span: 5 },"wrapperCol":{ span: 18, offset: 1 }}},[_c('a-range-picker',{attrs:{"value":_vm.dateValue,"ranges":{
                                    '今天': [_vm.moment(), _vm.moment()],
                                    '昨天': [_vm.moment().subtract(1, 'days'), _vm.moment().subtract(1, 'days')],'当月': [_vm.moment().startOf('month'), _vm.moment().endOf('month')],'上月': [_vm.moment().subtract(1, 'month').startOf('month'), _vm.moment().subtract(1, 'month').endOf('month')]
                                }},on:{"change":_vm.dateChange}})],1)],1),_c('a-col',{staticStyle:{"max-width":"400px"},attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"拜访时间","labelCol":{ span: 5 },"wrapperCol":{ span: 18, offset: 1 }}},[_c('a-range-picker',{attrs:{"value":_vm.dateVisiValue,"ranges":{
                                    '今天': [_vm.moment(), _vm.moment()],
                                    '昨天': [_vm.moment().subtract(1, 'days'), _vm.moment().subtract(1, 'days')],'当月': [_vm.moment().startOf('month'), _vm.moment().endOf('month')],'上月': [_vm.moment().subtract(1, 'month').startOf('month'), _vm.moment().subtract(1, 'month').endOf('month')]
                                }},on:{"change":_vm.dateVisiChange}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('a-button',{staticStyle:{"margin-top":"4px","margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.getData}},[_vm._v("查询")])],1)],1),_c('div',{staticStyle:{"margin-top":"0px","font-size":"15px","margin-left":"10px"}},[_vm._v("显示第 "+_vm._s(_vm.total>0 ? 1 : 0)+" 至 "+_vm._s(_vm.queryData.size > _vm.total ? _vm.total : _vm.queryData.size)+" 项结果，共 "+_vm._s(_vm.total)+" 项")])],1)])],1),_c('div',[_c('standard-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"loading":_vm.tableLoading,"pagination":false},scopedSlots:_vm._u([{key:"createTime",fn:function({text}){return _c('div',{},[_vm._v(_vm._s(_vm.formatDate(text)))])}},{key:"status",fn:function({text}){return _c('div',{},[(text == 1)?_c('span',{staticStyle:{"color":"#008000"}},[_vm._v("上架")]):_vm._e(),(text == 2)?_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("下架")]):_vm._e()])}},{key:"icon",fn:function({text}){return _c('div',{},[_c('img',{staticStyle:{"width":"45px"},attrs:{"src":text,"alt":""}})])}}],null,false,980190636)},[_c('span',{attrs:{"slot":"offlineVisitsTitle"},slot:"offlineVisitsTitle"},[_vm._v(" 线下拜访记录 "),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 在时间段内相同客户拜访多次仅统计一次 ")]),_c('a-icon',{attrs:{"type":"question-circle"}})],2)],1)]),_c('div',{staticClass:"page-box"},[_c('a-pagination',{attrs:{"total":_vm.total,"current":_vm.currentPage,"page-size-options":_vm.pageSizeOptions,"pageSize":_vm.queryData.size,"show-size-changer":""},on:{"change":_vm.handlePageChange,"showSizeChange":_vm.onShowSizeChange}})],1)],1)]):_c('div',{staticStyle:{"text-align":"center","font-size":"20px","margin-top":"100px"}},[_c('a-empty',{attrs:{"description":false}}),_vm._v(" 无权限查看 ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }